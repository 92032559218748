import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../config';

const axiosInstance = axios.create({
  baseURL: config.twr_api,
  headers: { 'Content-Type': 'application/json' },
});

export const createEquip = createAsyncThunk('equip/createEquip', async (newData) => {
  const response = await axiosInstance.post('/api/worker/contents/vehicle', newData);
  if (response.data.success && response.data.vehicle) {
    return { ...response.data.vehicle, key: response.data.vehicle.id };
  }
  return null;
});

export const getEquipAll = createAsyncThunk('equip/getEquipAll', async () => {
  const response = await axiosInstance.get('/api/worker/contents/vehicle/all');
  if (response.data.success) {
    return response.data.vehicles
      ?.filter((item) => item.isEnabled)
      .map((item) => ({ ...item, key: item.id }));
  }
  return [];
});

export const getEquipOne = createAsyncThunk('equip/getEquipOne', async (id) => {
  const response = await axiosInstance.get('/api/worker/contents/vehicle', { params: { id } });
  return response.data;
});

export const updateEquip = createAsyncThunk('equip/updateEquip', async ({ updateData, list }) => {
  const response = await axiosInstance.patch('/api/worker/contents/vehicle', updateData);
  if (response.data.success) {
    return list.map((item) =>
      item.id === updateData.uuid
        ? { ...response.data.vehicle, key: item.id }
        : item
    );
  }
  return list;
});

export const deleteEquip = createAsyncThunk('equip/deleteEquip', async ({ id, data }) => {
  const response = await axiosInstance.delete('/api/worker/contents/vehicle', { params: { id, enable: false } });
  if (response.data.success) {
    return data.filter((item) => item.id !== id);
  }
  return data;
});

const initialState = {
  list: [],
  status: 'idle',
  error: null,
};

const equipSlice = createSlice({
  name: 'equip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEquip.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEquip.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload) {
          state.list = [action.payload, ...state.list];
        }
      })
      .addCase(createEquip.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getEquipAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEquipAll.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(getEquipAll.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getEquipOne.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEquipOne.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(getEquipOne.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateEquip.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEquip.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(updateEquip.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteEquip.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteEquip.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(deleteEquip.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default equipSlice.reducer;
