import { message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authUser, setUserStateFromCookie } from '../store/user';
import Cookies from 'js-cookie';

const withAuth = (WrappedComponent, reload, adminRoute = null) => {
  return function AuthenticationCheck(props) {
    const user = useSelector(
      ({ user }) => ({
        isAuth: user.isAuth,
        isAdmin: user.isAdmin,
        isError: user.isError,
        loginSuccess: user.loginSuccess,
        userData: user.userData
      }),
      shallowEqual
    );
    const dispatch = useDispatch();
    const navigate = useNavigate(); 

    useEffect(() => {
      const cookieUser = Cookies.get('user');
      if (cookieUser) {
        const parsedUser = JSON.parse(cookieUser);
        dispatch(setUserStateFromCookie(parsedUser));
      } else if (user.loginSuccess && !user.isAuth) {
        dispatch(authUser());
      }
    }, [user.loginSuccess, dispatch]);

    useEffect(() => {
      if (user.loginSuccess || Cookies.get('user')) {
        if (user.isError) {
          if (reload !== false) {
            navigate('/login');
          }
        } else {
          if (user.isAuth || Cookies.get('user')) {
            const cookieUser = Cookies.get('user') && JSON.parse(Cookies.get('user'));
            const isAdmin = user.isAdmin || (cookieUser && cookieUser.role === 'ROLE_ADMIN');
            if (adminRoute && !isAdmin) {
              message.info('해당 페이지에 접근 권한이 없습니다.');
              navigate('/');
            } else {
              if (reload === false) navigate('/');
            }
          } else {
            navigate('/login');
          }
        }
      } else {
        if (reload) navigate('/login');
      }
    }, [navigate, user, reload, adminRoute]);

    return <WrappedComponent {...props} />;
  }
};

export default withAuth;
