import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../config';

const initialState = {
  mode: null,
  list: [],
  current: null,
  statusList: [],
  loading: false,
  error: null,
};

const axiosInstance = axios.create({
  baseURL: config.twr_api,
  headers: { 'Content-Type': 'application/json' },
});

export const getJourneyOne = createAsyncThunk('journey/getJourneyOne', async (id) => {
  if (!id) return null;

  const response = await axiosInstance.get('/api/worker/contents/journey', {
    params: { id, bDraftGeom: false, bRecordGeom: false, bPlanGeom: false },
  });

  if (response.data.success) {
    const { journey } = response.data;
    return {
      ...journey,
      key: journey.id,
      projectName: journey.projects[0]?.name,
      projectId: journey.projects[0]?.id,
      drafts: journey.projects[0]?.drafts,
      vehicleName: journey.vehicle?.name,
      planDate: journey.planStep?.insertTime,
      plannerName: journey.planStep?.planner?.userName,
      recordDate: journey.recordStep?.insertTime,
      recorderName: journey.recordStep?.uploader?.userName,
      uploadDate: journey.uploadStep?.insertTime,
      uploaderName: journey.uploadStep?.uploader?.userName,
      isVisible: journey.isEnabled,
      status: journey.status,
    };
  }
  return null;
});

export const getStatus = createAsyncThunk('journey/getStatus', async () => {
  const response = await axiosInstance.get('/api/worker/contents/journey/status');
  return response.data.success ? response.data.status : [];
});

export const createJourney = createAsyncThunk('journey/createJourney', async (createData) => {
  const { projectId, name, description } = createData;
  const response = await axiosInstance.post('/api/worker/contents/journey', { name, description }, { params: { projectId } });
  if (response.data.success) {
    const { journey } = response.data;
    return {
      ...journey,
      key: journey.id,
      projectName: journey.projects && journey.projects[0]?.name,
      projectId,
      vehicleName: journey.vehicle?.name,
      planDate: journey.planStep?.insertTime,
      plannerName: journey.planStep?.planner?.userName,
      recordDate: journey.recordStep?.insertTime,
      recorderName: journey.recordStep?.uploader?.userName,
      uploadDate: journey.uploadStep?.insertTime,
      uploaderName: journey.uploadStep?.uploader?.userName,
      isVisible: journey.isEnabled,
      status: journey.status,
    };
  }
  return null;
});

export const updateEnable = createAsyncThunk('journey/updateEnable', async ({ enable, journeyId }) => {
  const response = await axiosInstance.delete('/api/worker/contents/journey', { params: { id: journeyId, enable } });
  return response.data.success ? { enable, journeyId } : null;
});

export const updateJourney = createAsyncThunk('journey/updateJourney', async ({ uuid, values }) => {
  const { name, description } = values;
  const response = await axiosInstance.patch('/api/worker/contents/journey', { uuid, name, description });
  return response.data.success ? { ...response.data.journey, name, description } : null;
});

export const updateLine = createAsyncThunk('journey/updateLine', async (planLayer) => {
  const { id, lineColor, lineWidth, isEnabled } = planLayer;
  const response = await axiosInstance.patch('/api/worker/contents/journey/one/plan/line', {}, { params: { id, lineColor, lineWidth, enable: isEnabled } });
  return response.data.success ? planLayer : null;
});

export const updateStatus = createAsyncThunk('journey/updateStatus', async (updateData) => {
  const response = await axiosInstance.patch('/api/worker/contents/journey', updateData);
  return response.data.success ? response.data : null;
});

export const updatePlan = createAsyncThunk('journey/updatePlan', async (updateData) => {
  const response = await axiosInstance.patch('/api/worker/contents/journey/one/plan', updateData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data.success ? response.data : null;
});

export const updateRecord = createAsyncThunk('journey/updateRecord', async (updateData) => {
  const response = await axiosInstance.patch('/api/worker/contents/journey/one/record', updateData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const updateUpload = createAsyncThunk('journey/updateUpload', async (updateData) => {
  const response = await axiosInstance.patch('/api/worker/contents/journey/one/upload', updateData);
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const updateIe = createAsyncThunk('journey/updateIe', async (updateData) => {
  const response = await axiosInstance.patch('/api/worker/contents/journey/one/ie', updateData);
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const createRecord = createAsyncThunk('journey/createRecord', async (createData) => {
  const response = await axiosInstance.post('/api/worker/contents/journey/one/record', createData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const createUpload = createAsyncThunk('journey/createUpload', async (createData) => {
  const response = await axiosInstance.post('/api/worker/contents/journey/one/upload', createData);
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const createIe = createAsyncThunk('journey/createIe', async (createData) => {
  const response = await axiosInstance.post('/api/worker/contents/journey/one/ie', createData);
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

export const createPlan = createAsyncThunk('journey/createPlan', async (createData) => {
  const response = await axiosInstance.post('/api/worker/contents/journey/one/plan', createData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data.success ? response.data : { success: response.data.success, message: response.data.message };
});

const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },
    changeVisible(state, action) {
      const { visible, journeyId } = action.payload;
      state.list = state.list.map((item) =>
        item.id === journeyId ? { ...item, isVisible: visible } : item
      );
    },
    setAllList(state, action) {
      state.list = action.payload;
    },
    addList(state, action) {
      state.list = [...action.payload, ...state.list];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJourneyOne.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJourneyOne.fulfilled, (state, action) => {
        state.current = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getJourneyOne.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.statusList = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createJourney.pending, (state) => {
        state.loading = true;
      })
      .addCase(createJourney.fulfilled, (state, action) => {
        const updatedList = state.list.length > 0 ? [action.payload, ...state.list] : [action.payload];
        state.list = updatedList;
        state.current = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createJourney.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateEnable.fulfilled, (state, action) => {
        const { enable, journeyId } = action.payload;
        state.list = state.list.map((item) =>
          item.id === journeyId ? { ...item, isEnabled: enable, isVisible: enable } : item
        );
      })
      .addCase(updateJourney.fulfilled, (state, action) => {
        state.list = state.list.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
        state.current = action.payload;
      })
      .addCase(updateLine.fulfilled, (state, action) => {
        const updatedPlanLayers = state.current.planStep.planLayers.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
        const updatedPlanStep = { ...state.current.planStep, planLayers: updatedPlanLayers };
        state.current = { ...state.current, planStep: updatedPlanStep };
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.status;
        state.current = {
          ...state.current,
          status: updatedStep,
          statusDate: updatedStep?.updateTIme || updatedStep?.insertTime,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.planStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          planStep: updatedStep,
          planDate: updatedStep?.insertTime,
          plannerName: updatedStep?.planner?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.recordStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          recordStep: updatedStep,
          recordDate: updatedStep?.insertTime,
          recorderName: updatedStep?.uploader?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(updateUpload.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.uploadStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          uploadStep: updatedStep,
          uploadDate: updatedStep?.insertTime,
          uploaderName: updatedStep?.uploader?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(updateIe.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.ieStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          ieStep: updatedStep,
          ieDate: updatedStep?.insertTime,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.recordStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          recordStep: updatedStep,
          recordDate: updatedStep?.insertTime,
          recorderName: updatedStep?.uploader?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(createUpload.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.uploadStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          uploadStep: updatedStep,
          uploadDate: updatedStep?.insertTime,
          uploaderName: updatedStep?.uploader?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(createIe.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.ieStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          ieStep: updatedStep,
          ieDate: updatedStep?.insertTime,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        if (!action.payload.success) return;
        const updatedStep = action.payload.planStep;
        state.current = {
          ...state.current,
          status: action.payload.status,
          planStep: updatedStep,
          planDate: updatedStep?.insertTime,
          plannerName: updatedStep?.planner?.userName,
        };
        state.list = state.list.map((item) =>
          item.id === state.current.id ? state.current : item
        );
      });
  }
});

export const { changeMode, changeVisible, setAllList, addList } = journeySlice.actions;

export default journeySlice.reducer;
