import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../config';
import Cookies from 'js-cookie';


const initialState = {
  loginSuccess: false,
  isAuth: false,
  isAdmin: false,
  isError: false,
  userData: null,
  list: [],
  projectUsers: []
};

export const loginUser = createAsyncThunk('user/loginUser', async (loginData) => {
  const response = await axios.post(`${config.twr_api}/api/account/login`, loginData);
  Cookies.set('user', JSON.stringify(response.data.user), { expires: 1 });
  return response.data;
});

export const logoutUser = createAsyncThunk('user/logoutUser', async () => {
  const response = await axios.get(`${config.twr_api}/api/account/logout`);
  return response.data;
});

export const authUser = createAsyncThunk('user/authUser', async () => {
  const response = await axios.get(`${config.twr_api}/api/worker/account`);
  return response.data;
});

export const updateAuth = createAsyncThunk('user/updateAuth', async ({ id, auth }) => {
  const params = { id, auth };
  const response = await axios.patch(`${config.twr_api}/api/admin/account/one`, {}, { params });
  if (response.data.success) return params;
  return null;
});

export const getUserAll = createAsyncThunk('user/getUserAll', async () => {
  const response = await axios.get(`${config.twr_api}/api/admin/account/all`);
  if (response.data.success)
    return response.data.users.map((item) => ({ ...item, key: item.id }));
  return null;
});

export const manageUser = createAsyncThunk('user/manageUser', async ({ uuids, projectId, user, list }) => {
  const response = await axios.patch(
    `${config.twr_api}/api/worker/contents/project/manage`,
    { uuids },
    { params: { projectId } }
  );
  if (response.data.success) {
    if (response.data.nRes <= list?.length) {
      return list.filter((item) => item.id !== user.id);
    } else {
      if (list) list.push({ ...user, key: user.id });
      else list = [{ ...user, key: user.id }];
      return list;
    }
  }
  return null;
});

export const getUsersForProject = createAsyncThunk('user/getUsersForProject', async (projectId) => {
  const response = await axios.get(`${config.twr_api}/api/admin/account/all`, { params: { projectId } });
  if (response.data.success) {
    return response.data.users.map((item) => ({ ...item, key: item.id }));
  }
  return null;
});

export const updateUserEnable = createAsyncThunk('user/updateUserEnable', async ({ id, enable, list }) => {
  const params = { id, enable };
  const response = await axios.delete(`${config.twr_api}/api/admin/account/one`, { params });
  if (response.data.success) {
    return list.map((item) => item.id === id ? { ...item, isEnabled: enable } : item);
  }
  return null;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState: () => initialState,
    setUserStateFromCookie: (state, action) => {
      state.isAuth = true;
      state.isAdmin = action.payload.role === 'ROLE_ADMIN';
      state.userData = action.payload;
      state.loginSuccess = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        const response = action.payload;
        state.isAuth = response.success;
        state.isAdmin = response.user?.role === 'ROLE_ADMIN';
        state.isError = response.name === 'Error';
        state.loginSuccess = response.success;
        state.userData = response.user;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loginSuccess = false;
      })
      .addCase(authUser.fulfilled, (state, action) => {
        const response = action.payload;
        state.isAuth = response.success;
        state.isAdmin = response.user?.role === 'ROLE_ADMIN';
        state.isError = response.name === 'Error';
        state.loginSuccess = response.success;
        state.userData = response.user;
      })
      .addCase(updateAuth.fulfilled, (state, action) => {
        if (action.payload) {
          const { id, auth } = action.payload;
          state.list = state.list.map((item) => (item.id === id ? { ...item, role: auth } : item));
          state.projectUsers = state.projectUsers.map((item) => (item.id === id ? { ...item, role: auth } : item));
        }
      })
      .addCase(getUserAll.fulfilled, (state, action) => {
        state.list = action.payload ?? state.list;
      })
      .addCase(manageUser.fulfilled, (state, action) => {
        state.projectUsers = action.payload ?? state.projectUsers;
      })
      .addCase(getUsersForProject.fulfilled, (state, action) => {
        state.projectUsers = action.payload ?? state.projectUsers;
      })
      .addCase(updateUserEnable.fulfilled, (state, action) => {
        state.list = action.payload ?? state.list;
      });
  }
});

export const { resetState, setUserStateFromCookie } = userSlice.actions;

export default userSlice.reducer;
