// const env = process.env.REACT_APP_ENV;

// const targets = {
//   twr_compose_prod: {
//     api: 'http://wmtwr-back:9020',
//     geoserver: 'http://geoserver:8080/geoserver/webmms'
//   },
//   twr_compose_dev: {
//     api: 'http://wmtwr-back:9020',
//     geoserver: 'http://geoserver:8080/geoserver/webmms'
//   },
//   twr_compose_mac: {
//     api: 'http://localhost:9020',
//     geoserver: 'http://localhost:8080/geoserver/webmms'
//   }

// };

// const target = targets[env] || targets.dev;
// console.log('target');
// console.log(target);

// const config = {
//   geoserver: target.geoserver,
//   twr_api: target.api,
//   workspace: 'webmms'
// };

// const config = {
//   geoserver: 'http://localhost:3000',
//   twr_api: 'http://localhost:3000',
//   workspace: 'webmms'
// };

const config = {
  geoserver: '',
  twr_api: '',
  workspace: 'webmms'
};

module.exports = {
  config
};
