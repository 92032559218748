import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bbox: null
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setBbox: (state, action) => {
      state.bbox = action.payload;
    }
  }
});

export const { setBbox } = mapSlice.actions;
export default mapSlice.reducer;
