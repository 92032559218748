import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../config';

const initialState = {
  list: [],
  current: null,
  edit: false
};

export const getProjectAll = createAsyncThunk('project/getProjectAll', async () => {
  const params = { bDraftGeom: false, enable: true };
  const response = await axios.get(`${config.twr_api}/api/worker/contents/project/all`, { params });
  if (response.data.success) {
    return response.data.projects.map((item) => ({ ...item, key: item.id }));
  }
  return [];
});

export const getProjectOne = createAsyncThunk('project/getProjectOne', async (id) => {
  const params = { id, bUser: true, bDraft: true, bDraftGeom: false };
  const response = await axios.get(`${config.twr_api}/api/worker/contents/project`, { params });
  if (response.data.success) {
    if (response.data.project?.isEnabled)
      return { ...response.data.project, key: response.data.project.id };
  }
  return null;
});

export const deleteProject = createAsyncThunk('project/deleteProject', async ({ id, list }) => {
  const response = await axios.delete(`${config.twr_api}/api/worker/contents/project`, {
    params: { id, enable: 0 }
  });
  if (response.data.success) {
    return list.filter((item) => item.id !== id);
  }
  return list;
});

export const updateProject = createAsyncThunk('project/updateProject', async (updateData) => {
  const response = await axios.patch(`${config.twr_api}/api/worker/contents/project`, updateData);
  return response.data;
});

export const createProject = createAsyncThunk('project/createProject', async (newData) => {
  const response = await axios.post(`${config.twr_api}/api/worker/contents/project`, newData);
  return response.data;
});

export const addGeom = createAsyncThunk('project/addGeom', async (formData) => {
  const response = await axios.patch(`${config.twr_api}/api/worker/contents/project/geom`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  if (!response.data) throw new Error('Upload failed.');
  return response.data;
});

export const getJourneyForProject = createAsyncThunk('project/getJourneyForProject', async (projectId) => {
  const response = await axios.get(`${config.twr_api}/api/worker/contents/journey/all`, {
    params: {
      projectId,
      bDraftGeom: false,
      bRecordGeom: false,
      bPlanGeom: false
    }
  });
  if (response.data.success) {
    let journeyList = response.data.journeys.map((item) => ({
      ...item,
      key: item.id,
      missions: item.planStep?.planLayers,
      records: item.recordStep?.recordLayers,
      uploads: item.uploadStep?.recordLayers,
      isVisible: item.isEnabled,
      status: item.status,
      projectName: item.projects[0].name,
      projectId: projectId,
      vehicleName: item.vehicle?.name,
      planDate: item.planStep?.insertTime,
      plannerName: item.planStep?.planner?.userName,
      recordDate: item.recordStep?.insertTime,
      recorderName: item.recordStep?.uploader?.userName,
      uploadDate: item.uploadStep?.insertTime,
      uploaderName: item.uploadStep?.uploader?.userName
    }));
    return { projectId, journeyList };
  }
  return { projectId, journeyList: [] };
});

export const getJourneyForProjects = createAsyncThunk('project/getJourneyForProjects', async (projectIds) => {
  const response = await axios.post(
    `${config.twr_api}/api/worker/contents/journey/all`,
    { projectIds },
    {
      params: {
        bDraftGeom: false,
        bRecordGeom: false,
        bPlanGeom: false
      }
    }
  );
  if (response.data.success) {
    let journeyList = response.data.journeys.map((item) => ({
      ...item,
      key: item.id,
      missions: item.planStep?.planLayers,
      records: item.recordStep?.recordLayers,
      uploads: item.uploadStep?.recordLayers,
      isVisible: item.isEnabled,
      status: item.status,
      projectName: item.projects[0].name,
      projectId: item.projects[0].id,
      vehicleName: item.vehicle?.name,
      planDate: item.planStep?.insertTime,
      plannerName: item.planStep?.planner?.userName,
      recordDate: item.recordStep?.insertTime,
      recorderName: item.recordStep?.uploader?.userName,
      uploadDate: item.uploadStep?.insertTime,
      uploaderName: item.uploadStep?.uploader?.userName
    }));
    return journeyList;
  }
  return [];
});

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.current = action.payload;
    },
    editMode: (state, action) => {
      state.edit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectAll.fulfilled, (state, action) => {
        state.list = action.payload;
        state.edit = false;
      })
      .addCase(getProjectOne.fulfilled, (state, action) => {
        if (!action.payload) return;
        const { id } = action.payload;
        let newList = state.list.some((item) => item.id === id)
          ? state.list.map((item) => (item.id === id ? action.payload : item))
          : [...state.list, action.payload];
        state.list = newList;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
      })
      .addCase(createProject.fulfilled, (state, action) => {
        const { project, success } = action.payload;
        if (success) {
          const newProject = { ...project, key: project.id };
          state.list = [newProject, ...state.list];
          state.current = newProject.id;
        }
      })
      .addCase(addGeom.fulfilled, (state, action) => {
      })
      .addCase(getJourneyForProject.fulfilled, (state, action) => {
        const { projectId, journeyList } = action.payload;
        state.list = state.list.map((item) =>
          item.id === projectId ? { ...item, journeyList } : item
        );
      })
      .addCase(getJourneyForProjects.fulfilled, (state, action) => {
        const journeyList = action.payload;
        state.list = state.list.map((project) => {
          const journey = journeyList.filter((item) => item.projectId === project.id);
          return { ...project, journeyList: journey };
        });
      });
  }
});

export const { setCurrentProject, editMode } = projectSlice.actions;

export default projectSlice.reducer;
