import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userReducer from './user'; 
import projectReducer from './project';
import equipmentReducer from './equipment';
import journeyReducer from './journey';
import mapReducer from './map';

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  equipment: equipmentReducer,
  journey: journeyReducer,
  map: mapReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const resetStore = () => {
  store.dispatch({ type: 'RESET_STORE' });
};
