import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Spin } from 'antd';
import withAuth from '../../hoc/Auth' 

const LoginPage = lazy(() => import('../views/login'));
const RegisterPage = lazy(() => import('../views/register'));
const DashBoardPage = lazy(() => import('../views/dashboard'));
const JourneyPage = lazy(() => import('../views/journey'));
const JourneyTablePage = lazy(() => import('../views/journeyTablePage'));
const ReportsPage = lazy(() => import('../views/reports'));
const EquipmentPage = lazy(() => import('../views/equipment'));
const ProjectPage = lazy(() => import('../views/projects'));
const UsersPage = lazy(() => import('../views/users'));
const ProgramInfoPage = lazy(() => import('../views/programInfo'));
const ProjectDetailPage = lazy(() => import('../views/projectDetail'));
const ReferenceLinkPage = lazy(() => import('../views/referenceLink'));

const ProtectedDashBoardPage = withAuth(DashBoardPage, true, false);
const ProtectedJourneyPage = withAuth(JourneyPage, true, false);
const ProtectedReportsPage = withAuth(ReportsPage, true, false);
const ProtectedJourneyTablePage = withAuth(JourneyTablePage, true, false);
const ProtectedEquipmentPage = withAuth(EquipmentPage, true, true);
const ProtectedProjectPage = withAuth(ProjectPage, true, true);
const ProtectedProjectDetailPage = withAuth(ProjectDetailPage, true, true);
const ProtectedUsersPage = withAuth(UsersPage, true, true);
const ProtectedProgramInfoPage = withAuth(ProgramInfoPage, true, false);
const ProtectedReferenceLinkPage = withAuth(ReferenceLinkPage, true, false);

export default function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="loading">
            <Spin />
          </div>
        }>
        <Routes>
          <Route path="/" element={<ProtectedDashBoardPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/journey" element={<ProtectedJourneyPage />} />
          <Route path="/journey/:id" element={<ProtectedReportsPage />} />
          <Route path="/journeyList" element={<ProtectedJourneyTablePage />} />
          <Route path="/equipment" element={<ProtectedEquipmentPage />} />
          <Route path="/projects" element={<ProtectedProjectPage />} />
          <Route path="/projects/:id" element={<ProtectedProjectDetailPage />} />
          <Route path="/users" element={<ProtectedUsersPage />} />
          <Route path="/programInfo" element={<ProtectedProgramInfoPage />} />
          <Route path="/referenceLink" element={<ProtectedReferenceLinkPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
